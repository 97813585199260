import Head from 'next/head';


const Seo = ({ seo }) => {
  const { title, description } = seo;
  return (
    <>
      <Head>
        <title>Edulethics | {title}</title>
        <meta name="description" content={description} />
      </Head>
    </>
  )
}


export default Seo;
